<template>
    <div>
        <v-app>
            <v-card>
                <v-card-title>
                    Formato de datos
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="d-flex flex-column">
                            <span class="text-h6 font-weight-regular black--text"><i class="icon-orders"></i> Filtro por estado</span>
                            <v-select label="Store" solo readonly v-model="store" :items="storesList"></v-select>
                        </div>
                        <v-container fluid>
                            <v-card>
                                <v-card-title class="overline">Campos Pedido</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox dense v-model="allCheckboxesOrder" :label="allCheckboxesOrder ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3" v-for="(item,index) in ordersFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Cliente</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesCustomer" :label="allCheckboxesCustomer ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3" v-for="(item,index) in customerFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Dirección</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesAddress" :label="allCheckboxesAddress ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3" v-for="(item,index) in addressFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Estado</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesOrderstate" :label="allCheckboxesOrderstate ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="2" md="2" v-for="(item,index) in orderstateFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Tipo de Pedido</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesOrdertype" :label="allCheckboxesOrdertype ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4" v-for="(item,index) in ordertypeFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Store</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesStore" :label="allCheckboxesStore ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="3" v-for="(item,index) in storeFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Source</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox v-model="allCheckboxesSource" :label="allCheckboxesSource ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="2" md="2" v-for="(item,index) in sourceFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="success" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title class="overline">Campos Productos</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox color="#F95812" v-model="allCheckboxesOrderDetail" :label="allCheckboxesOrderDetail ? 'Deseleccionar todo':'Seleccionar todo'"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4" v-for="(item,index) in orderDetailFiltered" :key="index">
                                            <v-checkbox  :label="item.translate" color="#F95812" v-model="item.value"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-container>
                        <v-divider></v-divider>
                        <v-btn class="btn-starkoms-primary" @click="sendForm">Guardar</v-btn>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>

<script>
export default {
    data(){
        return {
            formato: [],
            storesList: [],
            store: undefined,
            allCheckboxesOrder: undefined,
            allCheckboxesCustomer: undefined,
            allCheckboxesAddress: undefined,
            allCheckboxesOrderstate: undefined,
            allCheckboxesOrdertype: undefined,
            allCheckboxesStore: undefined,
            allCheckboxesSource: undefined,
            allCheckboxesOrderDetail: undefined,
        }
    },
    mounted()
    {
        var vm = this;
        this.axios({
            url: 'orders/stores/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.storesList = response.data;
            vm.store = vm.$store.state.passport.user.default_store_id;
        }).catch( error => {
            console.log(error);
        });
        this.axios({
            url: 'orders/orders/orders_config_attributes',
            method: 'GET',
        }).then( response => {
            console.log(response.data);
            response.data.forEach( (elem) => {
                vm.formato.push({label: elem, value: false, translate: this.traducirLabel(elem)});
            });
            vm.fetchAttributesByStore();
        }).catch( error => {
            console.log(error);
        });
    },
    methods:{
        fetchAttributesByStore()
        {
            var vm = this;
            this.axios({
                url: 'orders/orders/orders_config_attributes_by_store',
                method: 'POST'
            }).then( response => {
                response.data.forEach( elem => {
                    let encontrado = vm.formato.find( felem => felem.label == elem);
                    if(encontrado){
                        encontrado.value = true;
                    }
                });
            }).catch( error => {

            });
        },
        traducirLabel(label){
            let palabras = label.split(".");
            let arreglo = [];
            palabras.forEach((e,index) => {
                let palabra = e;
                //if(index == 0){
                    let traduccion;
                    switch(palabra){
                        case 'order':
                            traduccion = 'orden';
                            break;
                        case 'customer':
                            traduccion = 'cliente';
                            break;
                        case 'address':
                            traduccion = 'direccion';
                            break;
                        case 'order_type':
                            traduccion = 'tipoPedido';
                            break;
                        case 'name':
                            traduccion = 'nombre';
                            break;
                        case 'created_at':
                            traduccion = 'fecha_creación';
                            break;
                        case 'updated_at':
                            traduccion = 'fecha_actualización';
                            break;
                        case 'deleted_at':
                            traduccion = 'fecha_eliminación';
                            break;
                        case 'order_state':
                            traduccion = 'estado';
                            break;
                        case 'order_detail':
                            traduccion = 'productos';
                            break;
                        default:
                            traduccion = palabra;
                            break;
                    }
                    arreglo.push(traduccion);
                //}else{
                //    arreglo.push(e)
                //}
            });
            return arreglo.join(".");   
        },
        sendForm()
        {
            var vm = this;
            this.axios({
                url: 'orders/orders/orders_export_config',
                method: 'POST',
                data: {
                    format: this.formato
                }
            }).then( response => {
                vm.$bvToast.toast("Configuracion cambiada correctamente!", {
                    title: `Notificación`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
            }).catch( error => {
                console.log(error);
            })
        }
    },
    watch:{
        allCheckboxesOrder(newValue, oldValue){
            if(newValue === true){
                this.ordersFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.ordersFiltered.every(e => e.value === true) ){
                this.ordersFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        ordersFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesOrder = false;
                }
            }
        },
        allCheckboxesCustomer(newValue, oldValue){
            if(newValue === true){
                this.customerFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.customerFiltered.every(e => e.value === true) ){
                this.customerFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        customerFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesCustomer = false;
                }
            }
        },
        allCheckboxesAddress(newValue, oldValue){
            if(newValue === true){
                this.addressFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.addressFiltered.every(e => e.value === true) ){
                this.addressFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        addressFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesAddress = false;
                }
            }
        },
        allCheckboxesOrderstate(newValue, oldValue){
            if(newValue === true){
                this.orderstateFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.orderstateFiltered.every(e => e.value === true) ){
                this.orderstateFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        orderstateFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesOrderstate = false;
                }
            }
        },
        allCheckboxesOrdertype(newValue, oldValue){
            if(newValue === true){
                this.ordertypeFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.ordertypeFiltered.every(e => e.value === true) ){
                this.ordertypeFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        ordertypeFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesOrdertype = false;
                }
            }
        },
        allCheckboxesStore(newValue, oldValue){
            if(newValue === true){
                this.storeFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.storeFiltered.every(e => e.value === true) ){
                this.storeFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        storeFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesStore = false;
                }
            }
        },
        allCheckboxesSource(newValue, oldValue){
            if(newValue === true){
                this.sourceFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.sourceFiltered.every(e => e.value === true) ){
                this.sourceFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        sourceFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesSource = false;
                }
            }
        },
        allCheckboxesOrderDetail(newValue, oldValue){
            if(newValue === true){
                this.orderDetailFiltered.forEach( (ele,index) => {ele.value = true;})
            }
            if( newValue === false && this.orderDetailFiltered.every(e => e.value === true) ){
                this.orderDetailFiltered.forEach( (ele,index) => {ele.value = false;})
            }
        },
        orderDetailFiltered:{
            deep: true,
            handler(newValue){
                if( newValue.some(e => e.value === false) ){
                    this.allCheckboxesOrderDetail = false;
                }
            }
        },
    },
    computed:{
        ordersFiltered(){
            return this.formato.filter(e => !e.label.includes('.'));
        },
        customerFiltered(){
            return this.formato.filter(e => e.label.includes('customer.'));
        },
        addressFiltered(){
            return this.formato.filter(e => e.label.includes('address.'));
        },
        orderstateFiltered(){
            return this.formato.filter(e => e.label.includes('order_state.'));
        },
        ordertypeFiltered(){
            return this.formato.filter(e => e.label.includes('order_type.'));
        },
        storeFiltered(){
            return this.formato.filter(e => e.label.includes('store.'));
        },
        sourceFiltered(){
            return this.formato.filter(e => e.label.includes('source.'));
        },
        orderDetailFiltered(){
            return this.formato.filter(e => e.label.includes('order_detail.'));
        }
    }
}
</script>